<template>
  <div class="content">
    <div class="page">
      <div class="img_demo">
        <img src="" alt="" />
        <div class="right">
          <h2>智慧党建</h2>
          <div class="message">
            以云计算、大数据、互联网为基础，建设全覆盖、全过程、全维度的党建信息化、智慧化的平台，
            实现线上线下相互融合、相互促进的党建工作新格局。
          </div>
        </div>
        <!-- <img src="../../assets/image/fbiImg/right_two.png" alt="" /> -->
      </div>
    </div>
  </div>
</template>
 
<script>
export default {
  components: {},
  name: "",
  data() {
    return {};
  },

  methods: {},
};
</script>
 
<style scoped lang="scss">
.content {
  padding: 0 10%;
  min-height: calc(100vh - 387px);
  .page {
    margin-top: 50px;
  }
  .img_demo {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    > img {
      display: inline-block;
      width: 50%;
      background: url("../../../../assets/image/ruralImg/img3.png") no-repeat;
      background-size: 100% 100%;
    }
    h2 {
      font-weight: bold;
      margin: 50px 30px 10px 50px;
    }
    .message {
      color: #6b6b6b;
      font-size: 16px;
      margin: 20px 30px 10px 50px;
    }
    .right {
      width: 50%;

      height: 400px;
      background-color: #fff;
    }
  }
}
</style>